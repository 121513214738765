.button {
    background-color: $color-bg;
    border: 2px solid $color-primary;
    height: 4rem;
    padding: 0 2rem;
    border-radius: 0.5rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.2rem;
    display: flex;
    align-items: center;
    color: $color-primary;
    line-height: 1em;
    cursor: pointer;
    @include ff-text;
    transition: all 0.2s ease-out;
    text-decoration: none;
    margin: 0.5rem 0;
    align-self: flex-start;

    &:hover {
        background-color: $color-primary;
        color: $color-primary-c;
    }

    &.small {
        font-size: 1.3rem;
        padding: 0.2rem 1rem 0;
        height: 3rem;
    }

    .icon {
        @include ff-icon;

        padding-right: 1rem;
        margin-left: -1rem;
    }

    .icon-right {
        padding-left: 1rem;
        margin-right: -1rem;
    }

    &.align-end {
        align-self: flex-end;
    }
}
