.clickable-element {
    display: flex;
    width: 100%;
    flex-direction: column;
    border: 1px solid $color-grey-2;
    padding: 1rem 6rem 1rem 1rem;
    border-radius: 0.5rem;
    position: relative;
    color: $color-black;
    text-decoration: none;
    margin: 0.5rem 0;
    cursor: pointer;

    &.no-cursor {
        cursor: default;
    }

    &__arrow {
        @include ff-icon;
        position: absolute;
        width: 6rem;
        height: 100%;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem 0 0;
    }

    &__error {
        position: absolute;
        right: 7rem;
        height: 100%;
        display: flex;
        align-items: center;
        top: 0;

        &-label {
            background-color: $color-red;
            color: $color-white;
            padding: 3px 10px;
            border-radius: 20px;
            font-weight: bold;
            font-size: 1.4rem;
        }
    }

    &__title {
        font-size: 2rem;
        font-weight: bold;
    }

    &__subtitle {
        font-size: 1.4rem;
    }

    &__button-right-icon {
        text-decoration: none;
        width: 5rem;
        height: 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:nth-last-child(1) {
            right: 1rem;
        }

        &:nth-last-child(2) {
            right: 6rem;
        }

        &::after {
            @include ff-icon;
            content: attr(data-icon);
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $color-primary;
            border-radius: 0.5rem;
            font-size: 2rem;
            color: $color-primary;
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            &::after {
                background-color: $color-primary;
                color: $color-white;
            }
        }
    }
}
