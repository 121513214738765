.page__form {
    &-row {
        display: flex;
        flex-wrap: wrap;

        &-errors {
            color: red;
            margin: 0.25rem 0;
            font-size: 1.2rem;
            flex: 0 0 100%;
        }

        &-input {
            flex: 1 0 30%;
            height: 4rem;
            font-size: 1.6rem;
            border: 1px solid $color-grey-4;
            padding: 0 1rem;

            &:nth-child(1) {
                border-radius: 0.5rem 0 0 0.5rem;
            }
        }

        &-label {
            flex: 1 0 30%;
            font-size: 1.6rem;
            text-align: right;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &-button {
            flex: 0 0 40px;
            height: 40px;
            background-color: white;
            border: 1px solid $color-grey-4;
            @include ff-icon;
            border-radius: 0 0.5rem 0.5rem 0;
            cursor: pointer;
        }
    }
}
