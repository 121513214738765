html {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
    font-size: 10px;
    min-width: 320px;
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    width: 100%;
    min-height: 100%;
    min-width: 320px;
    background-color: $color-bg;
    @include ff-text();
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

#root {
    position: relative;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    // height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 0 100%;
}

* {
    box-sizing: border-box;
    outline: none;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: center;
    padding-top: $nav-height;
    flex: 1 0 100%;
}
