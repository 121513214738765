.valid-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &::before {
        @include ff-icon;
        font-size: 16px;
        text-align: center;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        line-height: 0;
        content: "close";
        border-radius: 2px;
        background-color: red;
        color: white;
    }

    &.is-valid {
        &::before {
            background-color: $color-primary;
            content: "check";
        }
    }
}
