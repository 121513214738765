.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding-top: 2rem;

    &.main {
        max-width: unset;
        align-items: center;
        // @media (min-width: 600px) {
        //     min-width: 600px;
        // }
    }

    &.wide {
        max-width: 1200px;
    }

    &__container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    &__title {
        width: 100%;
        font-size: 3rem;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        padding: 1rem;
        line-height: 1.5em;
    }

    &__subtitle {
        width: 100%;
        font-size: 2.5rem;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        padding: 1rem;
        line-height: 1.5em;
    }

    &__text {
        width: 100%;
        text-align: left;
        font-size: 1.6rem;
        line-height: 1.5em;
        padding: 1rem;

        &.center {
            text-align: center;
        }

        a {
            color: $color-primary;
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    &__buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &.center {
            justify-content: center;
        }

        .button {
            margin-right: 1rem;
        }
    }

    &__prop {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;

        &-value {
            font-size: 2rem;
        }

        &-description {
            font-size: 1.4rem;
            margin-top: 0.5rem;
        }
    }
}

table {
    width: 100%;
    border-collapse: collapse;

    thead {
        tr {
            background-color: $color-primary;
            color: #fff;
        }
        th {
            font-size: 1.6rem;
            padding: 1rem 0;
            text-align: center;
        }
    }

    tbody {
        tr {
            &:nth-child(2n) {
                background-color: $color-grey-1;
            }
        }

        td {
            text-align: center;
            padding: 1rem 0;
            font-size: 1.4rem;
        }
    }
}
