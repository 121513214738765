@import "../scss/variables";

.center {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.sheet-list {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
}

.spreadsheet {
    width: 100%;
    margin: 10px 0;
    border: 1px solid #ccc;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    &__wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__name {
        padding: 10px;
        flex: 1 1 auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__small {
            color: #888;
            font-size: 12px;
        }
    }

    &__button-group {
        flex: 0 0 auto;
        display: flex;
        gap: 4px;
        align-items: center;
    }
}

.generate-refresh {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.range {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    background-color: #eee;
    border-top: 1px solid #ccc;

    &__name {
        flex: 1 0 auto;
        padding: 0 10px 0 0;
    }

    &__timestamp {
        padding: 0 10px;
    }
}
